<template>
  <HCaptcha
    ref="hCaptchaRef"
    @onChallengeExpired="onClose"
    @onClosed="onClose"
    @onError="onError"
    @onExpired="onClose"
    @onOpened="onOpen"
    @onVerify="onVerify"
  />
</template>

<script>
import HCaptcha from '@/components/misc/HCaptcha';

export default {
  name: 'Captcha',

  components: {
    HCaptcha,
  },

  mounted() {
    this.onSubmit();
  },

  methods: {
    onClose() {
      this.nativeAppPostMessage({
        success: false,
        message: 'user_canceled_action',
      });
    },

    onError(err) {
      this.nativeAppPostMessage({
        success: false,
        message: 'an_error_occured',
        err,
      });
    },

    onOpen() {
      this.nativeAppPostMessage({
        success: false,
        message: 'captcha_challenge_triggered',
      });
    },

    onSubmit() {
      this.$refs.hCaptchaRef.execute();
    },

    onVerify(captcha, eKey) {
      this.nativeAppPostMessage({
        success: true,
        captcha,
        eKey,
      });
    },
  },
};
</script>
